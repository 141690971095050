// 连锁设置
<template>
  <div class="item">
    <TitleHeader msg="连锁设置"></TitleHeader>
    <el-row class="MyAssociationMemberStoreDetail">
      <div class="LineBox">
          <span class="LineTit">已绑定总店：</span>
          <div class="AccountBox">{{list.enterpriseName}}</div>
      </div>
      <div class="LineBox">
          <span class="LineTit">联系人姓名：</span>
          <div class="AccountBox">{{list.linkman}}</div>
      </div>
      <div class="LineBox">
          <span class="LineTit">联系人电话：</span>
          <div class="AccountBox">{{list.mobile}}</div>
      </div>
      <div class="LineBox">
          <span class="LineTit">纳税人识别号：</span>
          <div class="AccountBox">{{list.creditCode}}</div>
      </div>
    </el-row>
  </div>
</template>
<style lang="less" scoped>
@import "~style/index.less";
.item {
  .MyAssociationMemberStoreDetail {
    padding: 30px;
    position: relative;
    .LineBox{
        margin-bottom:24px;
        .LineTit{
                width:160px;
                text-align: right;
                vertical-align: middle;
                float: left;
                font-size: 14px;
                color: #606266;
                line-height: 40px;
                padding: 0 12px 0 0;
                box-sizing: border-box;
        }
     
        .AccountBox{
            position: relative;
            display: inline-block;
            width:398px;
            font-size: 14px;
            height:38px;
            color: #666;
            border:1px solid #ccc;
            text-align: center;
            line-height: 40px;
        }
        
    }
  }

}
</style>
<script>
import {associationMemberStoreDetail} from "api/member.js"
const TitleHeader = () => import("components/common/TitleHeader.vue");
export default {
  name: "MyAssociatedAccount",
  data() {
   
    return {
        list:{},
    };
  },
  methods: {

  },
  created() {
        associationMemberStoreDetail().then(
              res=>{
                this.list=res.data.data
                console.log(this.list)

              }
          )
  },
  components: {
    TitleHeader,
  },
};
</script>
